<template>
  <div class="main">
    <commonTitle class="mb-10">每日打卡次数</commonTitle>
    <div v-if="show && false">
      <div
        class="daka-list"
        v-for="(items, i) in data"
        @click="changeDk(i)"
        :class="nowIndex === i ? 'on' : ''"
        :key="i"
      >
        <div class="left">{{ items.title }}：</div>
        <div class="right">
          <div
            class="flex-box"
            v-for="(item, index) in items.list"
            :key="index"
          >
            <!-- :default-value="moment(item.start,'HH:mm')" -->
            <a-time-picker
              v-model="item.start"
              format="HH:mm"
              :default-value="
                moment(
                  item.clock_start_date ? item.clock_start_date : item.start,
                  'HH:mm'
                )
              "
              @change="
                (value, valueStr) => {
                  changeStart(value, valueStr, index, items.list);
                }
              "
            />
            <span class="plr10">-</span>
            <!-- :default-value="moment(item.end,'HH:mm')" -->
            <!-- :disabledHours="() => disabledEndHours(item)"
              :disabledMinutes="() => disabledEndMinutes(item)" -->
            <a-time-picker
              v-model="item.end"
              format="HH:mm"
              :default-value="
                moment(
                  item.clock_start_date ? item.clock_end_date : item.end,
                  'HH:mm'
                )
              "
              @change="
                (value, valueStr) => {
                  changeEnd(value, valueStr, index, items.list);
                }
              "
            />
          </div>
        </div>
      </div>
    </div>

    <div v-if="show">
      <div v-if="!clockInList.length">
        <a-empty description="暂未设置打卡时间" />
      </div>

      <div v-for="(day, dayIndex) in clockInList" :key="day.id" class="clock-in-day">
        <div class="form-item">
          <div class="label required">{{ day.name }}</div>
          <a-range-picker v-model="day.range" value-format="YYYY-MM-DD" class="range-picker"/>
          <a-icon type="delete" class="ml-10 icon" @click="onDeleteDay(dayIndex)"/>
        </div>

        <template v-if="day.timeList?.length">
          <div v-for="(time, timeIndex) in day.timeList" :key="time.id" class="form-item clock-in-time">
            <div class="label required">{{ time.name }}</div>
            <a-time-picker v-model="time.start"  format="HH:mm"  value-format="HH:mm" placeholder="开始时间" class="time-picker"/>
            <span class="ml-10 mr-10">~</span>
            <a-time-picker v-model="time.end"  format="HH:mm"  value-format="HH:mm" placeholder="结束时间" class="time-picker"/>

            <div class="">
              <a-icon type="plus-circle" class="ml-10 icon" @click="onAddTime(day, timeIndex)"/>
              <a-icon v-if="day.timeList.length > 1" type="minus-circle" class="ml-10 icon" @click="onDeleteTime(day, timeIndex)"/>
            </div>

          </div>
        </template>
      </div>
    </div>

    <div class="add-date-button-wrapper">
      <a-button type="link" @click="onAddClockInDate">
        <span>+ 添加打卡日期</span>
      </a-button>
    </div>

    <div class="form-item">
      <div class="label required">打卡方式</div>
      <a-radio-group v-model="clockInType">
        <a-radio :value="1">定位</a-radio>
        <a-radio :value="2">扫码</a-radio>
        <a-radio :value="3">定位+扫码</a-radio>
      </a-radio-group>
    </div>

    <div v-if="[1, 3].includes(clockInType)" class="form-item">
      <div v-if="false" class="form-item" data-desc="去掉打卡地点，在班次里配置">
        <div class="label required">{{ addressInfo }}</div>
        <div class="right flex-box">
          <a-input
            placeholder="请输入"
            v-model.trim="address"
            disabled
            style="width: 410px; margin-right: 8px;"
          />
          <a-button type="primary" @click="selectClockInAddress">
            {{ title }}
          </a-button>
        </div>
      </div>

      <div class="form-item">
        <div class="label required">打卡范围</div>
        <a-input-number v-model="clockInRange" class="mr-10"/>
        <span>米</span>
      </div>
    </div>

    <saveButtons
      ref="saveButtons"
      @handleSubmit="handleSubmit"
      @saveAndNext="saveAndNext"
    ></saveButtons>
    <selectMap
      ref="clockIn"
      :title="title"
      :latLng="latLng"
      @success="onSuccess"
    ></selectMap>
  </div>
</template>

<script>
import moment from 'moment';
import { geocoder } from '@/components/map/api/commonMap';
import { TimePicker } from 'ant-design-vue';
import { getCheckWorkDetail, saveCheckWork } from '@/api/config';
import { ConfigMixin } from './mixins/configMixins';
import { mapState } from 'vuex';

export default {
  name: 'checkWorkAttendanceConfiguration',
  mixins: [ConfigMixin],
  components: {
    saveButtons: () => import('./components/saveButtons'),
    commonTitle: () => import('./components/commonTitle'),
    selectMap: () => import('./components/selectMap'),
    'a-time-picker': TimePicker,
  },
  computed: {
    ...mapState({
      trainingType: (state) => state.Offline.trainType,
    }),
  },
  data() {
    return {
      title: '选择打卡位置',
      addressInfo: '打卡地点',
      radioStyle: {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
      },
      formItemLayout: {
        labelCol: { span: 4 },
        wrapperCol: { span: 20 },
      },
      form: {},
      inputVisible: false,
      inputValue: '',
      nowIndex: 0,
      data: [
        {
          title: '一次',
          list: [{ start: moment(7, 'HH:mm'), end: moment(18, 'HH:mm') }],
        },
        {
          title: '两次',
          list: [
            {
              start: moment(7, 'HH:mm'),
              end: moment(12, 'HH:mm'),
            },
            {
              start: moment(13, 'HH:mm'),
              end: moment(18, 'HH:mm'),
            },
          ],
        },
        {
          title: '三次',
          list: [
            {
              start: moment(7, 'HH:mm'),
              end: moment(12, 'HH:mm'),
            },
            {
              start: moment(13, 'HH:mm'),
              end: moment(17, 'HH:mm'),
            },
            {
              start: moment(18, 'HH:mm'),
              end: moment(20, 'HH:mm'),
            },
          ],
        },
      ],
      clockInList: [
        {
          id: 1,
          index: 1,
          name: '打卡日期1',
          range: [undefined, undefined],
          timeList: [
            {
              id: '1-1',
              index: 1,
              name: '打卡时间1',
              start: undefined,
              end: undefined
            },
            {
              id: '1-2',
              index: 2,
              name: '打卡时间2',
              start: undefined,
              end: undefined
            }
          ]
        }
      ], // 打开日期及次数
      clockInType: 1, // 打卡方式
      clockInRange: 50, // 打卡范围（单位：米）
      address: '',
      show: false,
      latLng: [],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      this.show = false;
      this.loading = true;
      const data = await getCheckWorkDetail(this.$route.query.id).finally(
        () => (this.loading = false),
      );
      if (!data || data.error_code) {
        this.show = true;
        return;
      }


      const clockDate = JSON.parse(data.data.clock_date || '[]')
      clockDate.sort((a, b) => +a.day?.replaceAll?.('-', '') - +b.day?.replaceAll?.('-', ''))

      const list = []
      clockDate.forEach((data, index) => {
        const item = {
          id: Date.now() + Math.round(Math.random() * 1e5),
          index: index + 1,
          name: '打卡日期' + (index + 1),
          range: [data.startday || data.day || undefined, data.endday || data.day || undefined],
          timeList: data.time?.map((time, idx) => {
            return {
              id: Date.now() + Math.round(Math.random() * 1e6),
              index: idx + 1,
              name: '打卡时间' + (idx + 1),
              start: time.start || undefined,
              end: time.end || undefined
            }
          }) || []
        }
        list.push(item)
      })

      this.clockInList = list
      this.clockInType = +data.data.clock_type || 1
      this.show = true
      this.address = data.data?.address || ''
      const point = data.data.address_coor?.split?.(',') || []
      this.latLng = point
      this.address_coor = point
      this.clockInRange = data.data?.address_distance || 50
    },
    moment,

    onAddClockInDate() {
      const length = this.clockInList.length + 1

      this.clockInList.push({
        id: Date.now() + Math.round(Math.random() * 1e5),
        index: length,
        name: '打卡日期' + length,
        range: [undefined, undefined],
        timeList: [
          {
            id: Date.now() + Math.round(Math.random() * 1e6),
            index: 1,
            name: '打卡时间1',
            start: undefined,
            end: undefined
          }
        ]
      })
    },
    onAddTime(day, timeIndex) {
      const timeId = Math.max(...day.timeList.map(i => i.index)) + 1

      day.timeList.splice(timeIndex + 1, 0, {
        id: day.id + '-' + timeId,
        index: timeId,
        name: '打卡时间' + timeId,
        range: [undefined, undefined]
      })
    },
    onDeleteTime(day, timeIndex) {
      day.timeList.splice(timeIndex, 1)
    },
    onDeleteDay(dayIndex) {
      this.clockInList.splice(dayIndex, 1)
    },

    changeStart(time, timeString, i, list) {
      if (i > 0 && list[i].start <= list[i - 1].end) {
        this.$message.error('开始时间不能小于上一次的结束时间');
        list[i].start = list[i - 1].end.clone().add(1, 'h');
      }
      if (i >= 0 && list[i].start >= list[i].end) {
        this.$message.error('开始时间不能大于结束时间');
        list[i].start = list[i].start.clone().add(-1, 'h');
      }
    },
    changeEnd(time, timeString, i, list) {
      if (list[i].start >= list[i].end) {
        this.$message.error('结束时间不能小于开始时间');
        list[i].end = list[i].start.clone().add(1, 'h');
      }
    },
    handleSubmit() {
      if(!this.clockInList.length) {
        this.$message.warn('请至少添加一个打卡日期')
        return
      }

      const emptyErrorMsg = []
      const dateErrorMsg = []
      const timeErrorMsg = []
      const clockDate = JSON.parse(JSON.stringify(this.clockInList))
      clockDate.sort((a, b) => moment(a.range[0], 'YYYY-MM-DD').isBefore(moment(b.range[0], 'YYYY-MM-DD')) ? -1 : 1)
      for(let i = 0; i < clockDate.length; i += 1) {
        const day = clockDate[i]
        if (day.range.some(i => !i)) {
          emptyErrorMsg.push(day.name)
        }
        if(i < clockDate.length - 1) {
          const dayNext = clockDate[i + 1]
          if(moment(day.range[1], 'YYYY-MM-DD').isAfter(moment(dayNext.range[0], 'YYYY-MM-DD'))) {
            dateErrorMsg.push(day.name + ' 与 ' + dayNext.name)
          }
        }

        day.timeList.sort((a, b) => moment(a.start, 'HH:mm').isBefore(moment(b.start, 'HH:mm')) ? -1 : 1)
        for(let j = 0; j < day.timeList.length; j += 1) {
          const time = day.timeList[j]
          if (!time.start || !time.end) {
            emptyErrorMsg.push(day.name + '的' + time.name)
          }
          if (j < day.timeList.length - 1) {
            const timeNext = day.timeList[j + 1]
            if(moment(time.end, 'HH:mm').isAfter(moment(timeNext.start, 'HH:mm'))) {
              timeErrorMsg.push(day.name + '的' + time.name + ' 与 ' + timeNext.name)
            }
          }
        }
      }
      const errorMsg = [...dateErrorMsg, ...timeErrorMsg]
      if(errorMsg.length || emptyErrorMsg.length) {
        this.$message.warn({
          content: (h) => h('div', {
            style: {
              display: 'inline-block',
              maxWidth: '400px'
            }
          }, [
            emptyErrorMsg.length ? h('div', {}, '请选择日期时间：' + emptyErrorMsg.join('、')) : '',
            errorMsg.length ? h('div', {}, '以下日期时间存在重叠，请修改：' + errorMsg.join('、')) : ''
          ])
        })
        return
      }

      // if ([1, 3].includes(this.clockInType) && !this.address_coor) {
      //   this.$message.error(`请输入${this.addressInfo}！`);
      //   return;
      // }
      // if ([1, 3].includes(this.clockInType) && !this.latLng.length) {
      //   this.$message.error(`请${this.title}！`);
      //   return;
      // }
      // if ([1, 3].includes(this.clockInType) && !this.clockInRange) {
      //   this.$message.error(`请输入打卡范围！`);
      //   return;
      // }

      let params = {
        training_id: this.$route.query.id,
        clock_date: JSON.stringify(this.clockInList.map((day) => {
          return {
            startday: day.range[0],
            endday: day.range[1],
            time: day.timeList.map((time) => {
              return {
                start: time.start,
                end: time.end
              }
            })
          }
        })),
        clock_type: this.clockInType
      }
      if ([1, 3].includes(this.clockInType)) {
        params.address = this.address || '';
        params.address_coor = this.latLng + ''; // 坐标
        params.address_distance = this.clockInRange || ''
      }
      this.save(params);
    },
    async save(params) {
      this.loading = true;
      const data = await saveCheckWork(params).finally(
        () => (this.loading = false),
      );

      if (!data || data.error_code) {
        this.$message.error({ content: data?.message || '保存失败' });
        return;
      }
      this.$message.success({ content: data?.message || '保存成功' });
      if (this.trainingType === 2) {
        this.goNextStep('exam');
      } else {
        this.goNextStep('liveClassConfiguration');
      }
    },
    changeDk(i) {
      this.nowIndex = i;
    },
    selectClockInAddress() {
      this.$refs.clockIn.openMe();
    },
    async onSuccess(recode) {
      this.address = recode.addr;
      this.latLng = [recode.lng, recode.lat];
      this.address_coor = [recode.lng, recode.lat];
      if (!recode.addr) {
        const data = await geocoder({
          location: [recode.lat, recode.lng].toString(),
          get_poi: 1,
        });
        if (data?.status === 0) {
          this.address = data?.result?.address;
        }
      }
    },
    disabledStartHours(list, index) {
      let hours = [];
      if (!index) return hours;
      const end = list[index - 1].end;
      if (!end) return hours;
      const hour = moment(end).hour() - 1;
      for (let i = hour; i >= 0; i--) {
        hours.push(i);
      }
      return hours;
    },
    disabledStartMinutes(list, index) {
      let minutes = [];
      if (!index) return minutes;
      const end = list[index - 1].end;
      if (!end) return minutes;
      const minute = moment(end).minute() - 1;
      for (let i = minute; i >= 0; i--) {
        minutes.push(i);
      }
      return minutes;
    },
    disabledEndHours(item) {
      const hours = [];
      if (!item.start) return hours;
      const hour = moment(item.start).hour() - 1;
      for (let i = hour; i >= 0; i--) {
        hours.push(i);
      }
      return hours;
    },
    disabledEndMinutes(item) {
      const minutes = [];
      if (!item.start) return minutes;
      const minute = moment(item.start).minute();
      for (let i = minute; i >= 0; i--) {
        minutes.push(i);
      }
      return minutes;
    },
  },
};
</script>

<style lang="less" scoped>
.main {
  width: 700px;
  margin: 0 auto;
}

.form-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 24px;

  .label {
    flex: none;
    width: 100px;
    padding-right: 12px;
    text-align: right;

    &.required {
      position: relative;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      &::before {
        display: block;
        content: "*";
        margin-right: 5px;
        color: red;
      }
    }
  }

  .value {
    flex: auto;
  }
}

.clock-in-day {
  margin-bottom: 24px;

  .icon {
    font-size: 16px;
    cursor: pointer;
  }

  .range-picker {
    width: 327px;
  }

  .time-picker {
    width: 148px;
  }
}

.bg-grey {
  background-color: rgb(242, 242, 242);
  padding: 0 15px;

  .rt {
    flex: 1;
  }

  .lt {
    padding-right: 10px;
  }
}

.w550 {
  width: 550px;
}

.daka-list {
  display: flex;
  border: 1px solid #ddd;
  background-color: rgba(0, 0, 0, 0.05);
  margin-top: 10px;
  cursor: default;

  .left {
    width: 150px;
    text-align: right;
    padding: 10px;
    line-height: 50px;
  }

  .right {
    padding: 10px 0;
  }
}

.daka-list.on {
  border: 1px solid #76c8ef;
  background-color: rgba(24, 144, 255, 0.1);
}

.plr10 {
  padding: 0 10px;
}

.add-date-button-wrapper {
  margin: 24px 0 16px;
}
</style>
